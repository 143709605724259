/* https://stackoverflow.com/questions/50909438/missing-mapbox-css-using-react */
#mapbox {
  position:absolute;
  top:0;
  bottom:0;
  width:100%;
}

body {
  margin:0;
  padding:0;
}
